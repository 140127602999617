* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Heebo, sans-serif;
}
html, body, #root, .App  {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}

b,
strong {
  font-weight: bolder;
}
a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

:root {
  --primaryBrand: #87c9a9;
  --primaryBrandDark: #144d35;
  --primaryBrandLight: #2c7a59;
  --primaryWhite: #fafcfb;
  --white: #ffffff;
  --gray: #a3a3a3;
  --silver: #b8b8b8;
  --dark: #4d4d4d;
  --darkBlue: #0a2b5c;
  --error: #f51818;
}
html[data-theme='light'] {
  --primaryWhiteToDark: #fafcfb;
  --primaryBrandDarkToWhite: #144d35;
  --primaryBrandDarkToDark: #144d35;
  --primaryBrandLightToDark: #2c7a59;
  --primaryBrandToGray: #87c9a9;
  --primaryBrandToWhite: #87c9a9;
  --primaryBrandToLightSilver: #87c9a9;
  --primaryBrandToBlack: #87c9a9;
  --darkToPrimaryBrandDark: #4d4d4d;
  --darkToLightSilver: #4d4d4d;
  --darkToWhite: #4d4d4d;
  --gray: #a3a3a3;
  --grayToDark: #a3a3a3;
}

html[data-theme='dark'] {
  --primaryWhiteToDark: #3d3d3d;
  --primaryBrand: #144d35;
  --primaryBrandDarkToWhite: #ffffff;
  --primaryBrandDarkToDark: #3d3d3d;
  --primaryBrandLightToDark: #3d3d3d;
  --primaryBrandToGray: #949494;
  --primaryBrandToWhite: #fcfcfc;
  --primaryBrandToLightSilver: #d6d6d6;
  --primaryBrandToBlack: #3d3d3d;
  --darkToPrimaryBrandDark: #144d35;
  --darkToLightSilver: #d6d6d6;
  --darkToWhite: #ffffff;
  --gray: #757575;
  --silver: #757575;
  --grayToDark: #4d4d4d;
}
